/* CSS */
import "@assets/scss/all.scss";

/* JS */
import "@assets/js/lib/limet/jquery.cookies";
import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'lazysizes';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
// loads the Icon plugin
UIkit.use(Icons);


// Init jquery cookies
$('#cookie-consent').cookies();

//Init Browser-Check for Safari-Hacks
var ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
        //alert("chrome") // Chrome
    } else {
        //alert("safari") // Safari
        $("body").addClass("safari");
    }
}

// SCROLL-ELEMENTE
$(window).on('scroll', function(){
    var top = $(window).scrollTop()/4;
    $('.scrollturning').css('transform','rotate('+ top + 'deg)');
    //console.log('scroll top '+top);
});


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
